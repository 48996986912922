define(['$window', 'accessibilityFocusHelper'], function ($window, accessibilityFocusHelper) {

  class AccessibleModalHelper {
    constructor(containerElement, closeHandler, startElement, customFocusDelay = 250) {
      this.containerElement = containerElement;
      this.closeHandler = closeHandler;
      this.delay = customFocusDelay;

      if (containerElement) {
        this.load(startElement);
      }
    }

    load(startElement) {
      this.setUpFocusVariables();

      // Need to delay focus for JAWS/NVDA/iOS Voiceover
      $window.setTimeout(() => {
        if (startElement) {
          accessibilityFocusHelper.focus(startElement);
        } else {
          this.firstFocusableEl && this.firstFocusableEl.focus();
        }
      }, this.delay);
      this.addKeyBoardHandler();
    }

    /**
     * Reload is used to load a new group of elements (e.g. another step from the same modal) and set focus
     * @param newContainer - the parent element for everything we want to focus manage
     * @param newStartElement - the element to set focus to
     */
    reload(newContainer, newStartElement) {
      if (newContainer) {
        this.containerElement = newContainer;
        this.load(newStartElement);
      }
    }

    /**
     * Refresh is similar to reload except it does not set focus
     * - used when extending or reducing the elements covered by the focus handler
     * @param newContainer - the parent element for everything we want to focus manage
     */
    refresh(newContainer) {
      if (newContainer) {
        this.containerElement = newContainer;
        this.setUpFocusVariables();
      }
    }

    close() {
      this.removeKeyBoardHandler();
      this.focusedElBeforeOpen.focus();
    }

    /**
     * Set up the focus variables for tab handling
     */
    setUpFocusVariables() {
      this.focusedElBeforeOpen = this.focusedElBeforeOpen || document.activeElement;

      const focusableEls = this.containerElement.querySelectorAll('a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
      this.focusableEls = Array.prototype.slice.call(focusableEls).filter(el => el.offsetParent).filter(el => el.getAttribute('tabindex') !== '-1'); // Remove hidden elements
      this.firstFocusableEl = this.focusableEls[0];
      this.lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];
    }

    keyboardHandler(event) {
      const handleBackwardTab = () => {
        if (document.activeElement === this.firstFocusableEl) {
          event.preventDefault();
          this.lastFocusableEl.focus();
        }
      };
      const handleForwardTab = () => {
        if (document.activeElement === this.lastFocusableEl) {
          event.preventDefault();
          this.firstFocusableEl.focus();
        }
      };
      switch (event.key) {
        case 'Tab': {
          if (event.shiftKey) {
            handleBackwardTab();
          } else {
            handleForwardTab();
          }
          break;
        }
        case 'Esc': // IE compatibility
        case 'Escape': {
          event.stopPropagation();
          event.preventDefault();
          this.closeHandler();
          break;
        }
        default:
          break;
      }
    }

    addKeyBoardHandler() {
      this.eventListener = this.keyboardHandler.bind(this);
      document.addEventListener('keydown', this.eventListener);
    }

    removeKeyBoardHandler() {
      document.removeEventListener('keydown', this.eventListener);
    }
  }

  return AccessibleModalHelper;
});
